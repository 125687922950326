import React, { Component } from 'react'
import styled from 'styled-components'
import { SectionPage, Container, SectionTitle, MarkdownContent } from "../../components/Section"
import Video from '../../components/Video'

const PeopleGrid = styled.div`
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	margin:0 -10px;
`
const PeopleItemGrid = styled.div`
	position:relative;
	width:100%;
	padding:0 10px;
	@media(min-width:768px){
		flex:0 0 50%;
		max-width:50%;
	}
`

class PeoplesSection extends Component {
	render() {
		// const { data, peopleData } = this.props
		return(
			<SectionPage
				ept="80px"
				epb="80px"
				xpt="60px"
				xpb="60px"
				pt="40px"
				pb="40px"
				bg="#fff"
				className="section-lead"
			>
				<Container>
					<PeopleGrid>
						<PeopleItemGrid>
							<SectionTitle textAlign="left" ml="0px">Leading by Example</SectionTitle>
							<MarkdownContent>
								<p>Kevin Gray is a Florida native originally from Live Oak, FL. Growing up on a farm with his four siblings, he learned the value of hard work, dedication, and financial responsibility. After years in the banking industry, he decided to take a leap to help people like those he grew up with—hardworking, everyday individuals who need a company they can trust. Recognizing the steel industry as an opportunity to give back, he found a way to serve both rural farming communities and expanding cities.</p>
								<p>Gray works diligently to simplify the buying process for customers. He takes pride in knowing that he and the team at ProBuilt Steel Buildings prioritize <strong>value</strong>, <strong>honesty</strong>, and <strong>integrity</strong> above all else while delivering unmatched customer service.</p>
							</MarkdownContent>
							{/* <SectionTitle textAlign="left" ml="0px">{data.title} </SectionTitle>
							<MarkdownContent dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />	 */}
							{/* {
								peopleData.edges.map((item, i)=>{
									return(
										<PeopleCard>											
											<PeopleBody>
												<h3 className='h2'>{item.node.name}</h3>
												<span>{item.node.role}</span>
												<p>{item.node.description?.description}</p>
											</PeopleBody>
										</PeopleCard>
									)
								})
							}		 */}
						</PeopleItemGrid>
						<PeopleItemGrid><Video /></PeopleItemGrid>
					</PeopleGrid>
				</Container>
			</SectionPage>
		)
	}
}

export default PeoplesSection